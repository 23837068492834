<template>
  <div id="data-list">
    <vx-card
      ref="filterCard"
      title="Filters"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer">
        <form ref="form" slot-scope="{ validate }" autocomplete="off">
          <div class="vx-row">
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary">*</span>Company
              </label>
              <validation-provider
                name="company_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  label="name"
                  :reduce="(name) => name.id"
                  :options="companies"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="items.company_id"
                  class="mb-4"
                  :clearable="false"
                  placeholder="Company"
                  :class="{ 'is-valid': !!errors[0] }"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75"> Departments</label>

              <v-select
                :reduce="(name) => name.id"
                label="name"
                :options="departments"
                name="department_id"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.department_id"
                class="mb-4"
                placeholder="Department"
              />
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">Job Title</label>
              <v-select
                :reduce="(name) => name.id"
                label="name"
                name="jobtitle_id"
                :options="jobTitles"
                :clearable="false"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="items.jobtitle_id"
                class="mb-4"
                placeholder="Job Title"
              />
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
              Category
              </label>
              <validation-provider
                name="category_id"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  label="name"
                  :reduce="(name) => name.id"
                  :options="categories"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="items.category_id"
                  class="mb-4 md:mb-0"
                  placeholder="Category"
                  :clearable="false"
                  :class="{ 'is-valid': !!errors[0] }"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">Employee Name</label>
              <v-select
                label="FullName"
                :reduce="(FullName) => FullName.id"
                :options="employee"
                class="w-full"
                placeholder="Emloyee Name"
                v-model="items.user_id"
              />
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">
                <span class="text-primary"></span>level
              </label>
              <validation-provider
                name="level"
                rules="required|min:1"
                v-slot="{ errors }"
              >
                <v-select
                  :reduce="name => name.id"
                  label="name"
                  :name="'level' + index"
                  :options="levels"
                  :clearable="false"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                  v-model="items.level"
                  class="mb-4 md:mb-0"
                  placeholder="Level"
                  :danger="!!errors[0]"
                  :danger-text="errors[0]"
                />
                <span>
                </span>
              </validation-provider>
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">Employee Code</label>
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                v-model="items.code"
              />
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">Employee National ID</label>
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                v-model="items.employee_national_id"
              />
            </div>
            <div class="vx-col md:w-1/5 sm:w-1/2 w-full mb-2">
              <label class="text-sm opacity-75">Manager Name</label>
              <v-select
                label="FullName"
                :reduce="(FullName) => FullName.id"
                :options="employee"
                class="w-full"
                placeholder="Manager Name"
                v-model="items.manager_id"
              />
            </div>
            <div class="vx-col md:w-1/4 sm:w-1/2 w-full mt-6">
              <vs-button
                type="filled"
                @click="validate().then(addNewFilter)"
                class="w-full mb-2"
                >Filters</vs-button
              >
            </div>
          </div>
        </form>
      </validation-observer>
    </vx-card>
    <vx-card class="vs-con-loading__container" id="div-with-loading">
      <div class="p-6">


        <div class="flex flex-wrap items-center">
          <!-- ITEMS PER PAGE -->
          <div class="flex-grow">
                  <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">
                  {{
                    currentPage * paginationPageSize - (paginationPageSize - 1)
                  }}
                  -
                  {{
                    itemsData.length - currentPage * paginationPageSize > 0
                      ? currentPage * paginationPageSize
                      : itemsData.length
                  }}
                  From {{ itemsData.length }}
                </span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                  <span>20</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="gridApi.paginationSetPageSize(40)">
                  <span>30</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <!-- <vs-input
            class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          />
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()"
            >Export CSV</vs-button
          > -->

                  <vs-input
            class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            placeholder="Search..."
          />
          <vs-button class="mb-4 md:mb-0" @click="exportToExcel()"
            >Export Excel</vs-button
          >
        </div>

        <!-- AgGrid Table -->
        <ag-grid-vue
          ref="agGridTable"
          :components="components"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="itemsData"
          rowSelection="multiple"
          :animateRows="true"
          :floatingFilter="false"
          :pagination="true"
          :paginationPageSize="paginationPageSize"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl"
        ></ag-grid-vue>

        <vs-pagination :total="totalPages" :max="7" v-model="currentPage" />
      </div>
    </vx-card>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import endpoints from "../../../endpoints";
import axios from "@/axios.js";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});
extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});

// Store Module
import moduleReportingToManagement from "@/store/get-all-reporting-to/moduleReportingToManagement.js";
import moduleCompaniesManagement from "@/store/get-all-companies/moduleCompaniesManagement.js";
import moduleDepartmentManagement from "@/store/get-all-departments/moduleDepartmentManagement.js";
import moduleJobTitleManagement from "@/store/get-all-jobs/moduleJobTitleManagement.js";
import moduleCategoriesManagement from "@/store/get-all-categories/moduleCategoriesManagement.js";
import moduleEmployeeManagement from "@/store/get-all-employees/moduleEmployeeManagement.js";

// Cell Renderer
import CellRendererLink from "../../hr-report/main-report/cell-renderer/CellRendererLink.vue";
import CellRendererStatus from "../../hr-report/main-report/cell-renderer/CellRendererStatus.vue";
// Router to navigate
// import router from "@/router";


export default {
  components: {
    AgGridVue,
    vSelect,
    flatPickr,
    // Cell Renderer
    ValidationObserver,
    ValidationProvider,
    CellRendererLink,
    CellRendererStatus,
  },
  data() {
    return {
      page: 1,
      manager_name: "",
      Employee:null,
      DirectManager:null,
      show: false,
      categorys: [{ name: "Operation" }, { name: "Staff" }],
      absenteeismDays: [{ name: 0 }, { name: 1 }, { name: "All" }],
      cities: [],
      areas: [],
      area_names: [],
      job_titles: [],
      isMounted: false,
      departments: [],
      jobTitles: [],
      department: null,
      city: null,
      category: null,
      items: {
        company_id: null,
        department_id: null,
        jobtitle_id: null,
        user_id: null,
        code: null,
        level: null,
        category_id: null,
        employee_national_id: null,
        manager_id: null,
      },
      configFromdateTimePicker: {
        allowInput: true,
        minDate: null,
        maxDate: new Date(),
      },
      configTodateTimePicker: {
        minDate: null,
        maxDate: new Date(),
      },

      searchQuery: "",
      levels: [
        { id: 1, name: "Level 1" },
        { id: 2, name: "Level 2" },
        { id: 3, name: "Level 3" },
        { id: 4, name: "Level 4" },
        { id: 5, name: "Level 5" },
        { id: 6, name: "Level 6" },
        { id: 7, name: "Level 7" },
        { id: 8, name: "Level 8" },
        { id: 9, name: "Level 9" },
        { id: 10, name: "Level 10" },
        { id: 11, name: "Level 11" },
        { id: 12, name: "Level 12" },
        { id: 13, name: "Level 13" },
        { id: 14, name: "Level 14" },
        { id: 15, name: "Level 15" },
        { id: 16, name: "Level 16" },
        { id: 17, name: "Level 17" },
        { id: 18, name: "Level 18" },
        { id: 19, name: "Level 19" },
        { id: 20, name: "Level 20" },
        { id: 21, name: "Level 21" },
        { id: 22, name: "Level 22" },
        { id: 23, name: "Level 23" },
        { id: 24, name: "Level 24" },
        { id: 25, name: "Level 25" },
        { id: 26, name: "Level 26" },
        { id: 27, name: "Level 27" },
        { id: 28, name: "Level 28" },
        { id: 29, name: "Level 29" },
        { id: 30, name: "Level 30" }
      ],
      // AgGrid
      gridApi: null,
      gridOptions: {},
      defaultColDef: {
        resizable: true,
      },
      columnDefs: [
        // {
        //   headerName: "ID",
        //   field: "id",
        //   sortable: true,
        //   filter: true,
        //   width: 150,
        // },
        {
          headerName: "Code",
          field: "code",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Employee Name",
          field: "employee_name",
          sortable: true,
          filter: true,
        },
        //Employee ID
        {
          headerName: "Employee ID",
          field: "employee_id",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Company",
          field: "company",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Category",
          field: "category",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Department",
          field: "department",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Job Title",
          field: "jobtitle",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Direct Manager",
          field: "DirectManager",
          sortable: true,
          filter: true,
        },
        {
          headerName: "Level",
          field: "Level",
          sortable: true,
          filter: true,
        },
      ],
      headerTitle: [
        "Code",
        "Employee Name",
        "Employee ID",
        "Company",
        "Category",
        "Department",
        "Job Title",
        "Direct Manager",
        "Level",
      ],
      headerVal: [
        "code",
        "employee_name",
        "employee_id",
        "company",
        "category",
        "department",
        "jobtitle",
        "DirectManager",
        "Level",
      ],
      fileName: "ReportingTo Excel",
      formats: ["xlsx", "csv", "txt", "dbf"],
      cellAutoWidth: true,
      selectedFormat: "xlsx",

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
      },
    };
  },
  watch: {
    "items.company_id"(obj) {
      this.items.department_id = null;
      this.items.jobtitle_id = null;
      this.departments = [];
      this.jobTitles = [];
      // console.log('obj', obj)
      if (obj) {
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
        axios
          .get(`${endpoints.API_URL}company/${obj}/departments`)
          .then((response) => {
            // console.log('response.data.response.data', response.data.response.data)
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.departments = response.data.response.data;
          });
      }
    },
    "items.department_id"(obj) {
      this.items.jobtitle_id = null;
      this.jobTitles = [];
      if (obj) {
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
        axios
          .get(`${endpoints.API_URL}department/${obj}/job_titles`)
          .then((response) => {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.jobTitles = response.data.response.data;
          });
      }
    },
  },
  computed: {
    companies() {
      return this.$store.state.company.items;
    },
    categories() {
      return this.$store.state.category.items;
    },
    itemsData() {
      return this.$store.state.reporting_to.items;
    },
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    },
    employee() {
      return this.$store.state.employees.items;
    },
  },
  methods: {
    // Fetch reporting_to Data without manager_name filter
    fetchItemsData(val) {
      console.log('val', val)
      this.$store
        .dispatch("reporting_to/fetchItemsReportingTo", val)
        .catch((err) => {
          this.$vs.notify({
            title: "Error",
            text: err,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
    },
    onFromChange(selectedDates, dateStr) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column);
      let modelObj = null;

      if (val !== "all") {
        modelObj = { type: "equals", filter: val };
      }

      filter.setModel(modelObj);
      this.gridApi.onFilterChanged();
    },
    resetColFilters(card) {
      card.removeRefreshAnimation(500);
      this.items.from = this.items.to = this.items.company_id = this.items.jobtitle_id = this.items.department_id  = this.items.employee_name =
        this.items.employee_code = this.items.employee_national_id = this.items.manager_id = null;
      // Reset Grid Filter
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    getCompanies() {
      this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
      this.$store.dispatch("company/fetchItemsCompanies").then((response) => {
        console.log('responseCompany', response)
        this.companies = response.response.data
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getCategories() {
      this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
      this.$store.dispatch("category/fetchItemsCategories").then((response) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getDepartments() {
      this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
      this.$store.dispatch("department/getAlls").then((response) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getJobTitles() {
      this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
      this.$store.dispatch("jobTitle/getAlls").then((response) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    getEmployee() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      this.$store.dispatch("employees/getAlls").then((response) => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        if (response.data.status === 404) {
          this.$vs.notify({
            title: "Error",
            text: "Make sure select the main filters ",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
      });
    },
    addNewFilter (params) {
      if( params === false ) {
          params = true
        }
      if (
        !this.items.company_id
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Make sure select the main filters ",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return;
      }
      if (params) {
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
        let params = {
          company_id: this.items.company_id,
          jobtitle_id: this.items.jobtitle_id,
          category_id: this.items.category_id,
          user_id: this.items.user_id,
          level: this.items.level,
          code: this.items.code,
          employee_national_id: this.items.employee_national_id,
          manager_id: this.items.manager_id,
          department_id: this.items.department_id
        };
        this.$store.dispatch("reporting_to/fetchItemsReportingTo", params)
          .then((response) => {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            console.log('response.data.response.data', response)
            if (response.data.response.data.length === 0) {
              this.$vs.notify({
                title: "Information",
                text: "No data available",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
            }
          })
          .catch((error) => {
            console.log('error', error)
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
            this.$vs.notify({
              title: "Error",
              text: error.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
          this.$vs.loading.close("#div-with-loading > .con-vs-loading");
    } else {
        // form have errors
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
      }
  },
  exportToExcel() {
      import("@/vendor/Export2Excel").then((excel) => {
        const list = this.itemsData;
        const data = this.formatJson(this.headerVal, list);

        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat,
        });
        this.clearFields();
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          // Add col name which needs to be translated

          // if (j === "attendances") {
          //   // console.log(JSON.stringify(v[j]));
          //   return JSON.stringify(v[j]);
          // } else if (j === "areas") {
          //   return v[j].map((i) => i.name);
          // } else {
          //   return v[j];
          // }

          return v[j];
        })
      );
    },
},
  mounted() {
    this.gridApi = this.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
     this.$store.dispatch("reporting_to/fetchItemsReportingTo" )
     .then(response => {
      this.$vs.loading.close("#div-with-loading > .con-vs-loading");
      if (response.data.status === 404) {
        this.$vs.notify({
          title: "Error",
          text: "Make sure select the main filters ",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      }
    });
    this.getCompanies();
    this.getDepartments();
    this.getCategories();
    this.getJobTitles();
    this.getEmployee();
    // this.isMounted = true;
  },
  created() {
    if (!moduleReportingToManagement.isRegistered) {
      this.$store.registerModule("reporting_to", moduleReportingToManagement);
      moduleReportingToManagement.isRegistered = true;
    }
    // this.$store.dispatch("hrReportManagement/fetchItemsCompanies ").catch(err => {
    //   console.error(err);
    // });
    if (!moduleCompaniesManagement.isRegistered) {
      this.$store.registerModule("company", moduleCompaniesManagement);
      moduleCompaniesManagement.isRegistered = true;
    }
    if (!moduleDepartmentManagement.isRegistered) {
      this.$store.registerModule("department", moduleDepartmentManagement);
      moduleDepartmentManagement.isRegistered = true;
    }
    if (!moduleJobTitleManagement.isRegistered) {
      this.$store.registerModule("jobTitle", moduleJobTitleManagement);
      moduleJobTitleManagement.isRegistered = true;
    }
    if (!moduleCategoriesManagement.isRegistered) {
      this.$store.registerModule("category", moduleCategoriesManagement);
      moduleCategoriesManagement.isRegistered = true;
    }
    if (!moduleEmployeeManagement.isRegistered) {
      this.$store.registerModule("employees", moduleEmployeeManagement);
      moduleEmployeeManagement.isRegistered = true;
    }
  },
};
</script>

<style lang="scss">
#data-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

[dir="rtl"] .ag-theme-material .ag-rtl {
  text-align: right;
}
[dir="rtl"] .ag-rtl {
  direction: rtl;
}
</style>
